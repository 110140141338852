
<template>
    <Status />
  </template>
  
  <script>
  import Status from "@/components/instellingen/Status";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Status,
    },
    metaInfo: {
   title: "Status bekijken",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>