<template>
  <v-container class="pt-10">
    <v-row>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          placeholder="Status"
          v-model="form.naam"
        ></v-text-field>

        <v-btn color="primary" @click="addStatus">
          Status toevoegen
        </v-btn>
      </v-col>

      <v-col cols="8">
        <v-card>
          <v-card-title>
            <span>Status</span>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Status opzoeken"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="statussen"
            :search="search"
            height="200px"
            fixed-header
            disable-pagination
            hide-default-footer
            class="elevation-1"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiStatus from "../api/apiStatus";
export default {
  data: () => ({
    form: {
      naam: "",
    },
    statussen: [],
    search: "",
    headers: [
      { text: "Naam", value: "naam", sortable: true },
      { text: "Acties", value: "actions", sortable: false },
    ],
  }),

  async mounted() {
    await this.getStatussen();
  },

  methods: {
    async getStatussen() {
      try {
        const data = await apiStatus.haalStatusOp();
        this.statussen = data;
      } catch (error) {
        console.error("Error fetching statussen:", error);
      }
    },
    resetForm() {
      this.form.naam = "";
    },
    async addStatus() {
      if (!this.form.naam) {
        this.$swal.fire("Foutmelding", "Vul het veld in", "error");
        return;
      }

      try {
        await apiStatus.createStatus(this.form);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        await this.getStatussen();
      } catch (error) {
        console.error("Error adding status:", error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    async deleteStatus(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze status wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiStatus.deleteStatus(id);
          await this.getStatussen();
          this.$swal.fire(
            "Verwijderd",
            "De status is succesvol verwijderd",
            "success"
          );
        } catch (error) {
          console.error("Error deleting status:", error);
          this.$swal.fire(
            "Er is een foutmelding!",
            "Probeer het nogmaals!",
            "error"
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.table-container {
  max-height: 500px;
  overflow-y: auto;
}
</style>
